import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Hero from '../components/Hero'
import logo from '../images/logo_redasa.svg'
import logo_amorphic from "../images/Logo_amorphic.svg"
import logo_mirrorweb from "../images/Logo_mirrorweb.svg"
import logo_aws from "../images/Logo_aws.png"
import InterestForm from '../components/InterestForm'
import { GatsbySeo } from 'gatsby-plugin-next-seo'


export const query = graphql`
  query {
    hero: file(relativePath: { eq: "tower_hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth:1600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ({data}) => {

  return (
  <>
    <GatsbySeo title='PanSurg REDASA'
          description='REaltime DAta Synthesis and Analysis'/>
      <Hero title="PanSurg REDASA"
            subtitle="REaltime DAta Synthesis and Analysis"
            fluid={data.hero.childImageSharp.fluid}
            breadcrumbs
        secondcrumb = 'Research'
        secondlink = '/research'
        activecrumb = 'RESADA'
        />

  <section className="section is-small" style={{marginTop:'2rem'}}>
    <div className="container">
      <div className="is-thin">

      <div className="has-text-centered">
        <img src={logo} width="400px" alt="REDASA Logo" style={{marginBottom:'2rem'}}/>
      </div>

      <div className="content">
        <p className="title has-text-darkBlue is-spaced">The PanSurg collaborative urgently needs data curators to help with
        a critical project.</p>

        <InterestForm name="interest_REDASA" />

        <br />

        <p>This highly innovative project is a collaboration between Imperial College London,
        Amazon Web Services, Cloudwick, MirrorWeb and the PanSurg Collaborative.
        It will create software tools that aim to dynamically assimilate the corpus of
        knowledge from traditional academic literature, websites and social media.
        In response to COVID-19 queries, REDASA will be able to perform automated
        data curation using machine learning from over 400,000 data sources and rapidly
        output focussed answers for clinicians, patients and policy makers.</p>

        <p>This methodology requires volunteers with a background in medicine and reviewing
        scientific content, to support analysis and assessment of the data that will drive REDASA.</p>

        <p><b>Data curation is run in "stints" and
        the top curator for each stint will win £100 in Amazon vouchers.</b> This will be awarded at the end of
        each curation stint to the curator who has submitted the highest number of labelled documents
        (subject to meeting the expected curation quality).</p>

        <p>If you would like to contribute to laying the foundation for the world’s first
        real-time systematic review tool and deliver a game-changing tool in the
        fight against COVID-19 please get in touch using the form above.</p>
      </div>

      </div>
    </div>
  </section>

  <section className="section">
    <div className="container">
      <div className="is-thin">

      <p className="subtitle has-text-darkBlue">Industry Collaborators</p>

      <div className="logos is-flex-tablet" style={{justifyContent:'space-between'}}>
        <a href="https://aws.amazon.com"><img width="100px" src={logo_aws} alt="aws_logo"/></a>
        <a href="https://www.mirrorweb.com/"><img width="200px" src={logo_mirrorweb} alt="mirrorweb_logo"/></a>
        <a href="https://www.amorphicdata.com/"><img width="200px" src={logo_amorphic} alt="amorphicdata_logo"/></a>
      </div>

      </div>
    </div>
  </section>


  </>
  )
}
