import React from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import {
  FaEnvelope
} from 'react-icons/fa'
import * as Yup from 'yup'

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const InterestForm = ({name}) => {

  return (

    <Formik
      initialValues={{email: '',}}

      onSubmit={(values, actions) => {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": name, ...values })
        })
        .then(() => {
          alert('Thank you for registering your interest, we will be in touch! PanSurg Team.');
          actions.resetForm()
        })
        .catch(() => {
          alert('Error');
        })
        .finally(() => actions.setSubmitting(false))
      }}

      validationSchema={Yup.object({
        email: Yup.string()
          .email('Invalid email address')
          .required('Required'),
      })}
>
{() => (
        <Form name={name} data-netlify={true} style={{maxWidth:'576px', margin:'auto'}}>
          <div className="field has-addons">

            <div className="control has-icons-left is-expanded">
              <Field
                className="input"
                name="email"
                placeholder="Your email address"
              />
              <span className="icon is-small is-left">
              <FaEnvelope color="grey"/>
              </span>
            </div>

            <button type="submit" className="button is-red">
              I'm interested!
            </button>

          </div>
          <ErrorMessage name="email">{msg => <div className="has-text-red">{msg}</div>}</ErrorMessage>
        </Form>
      )}
    </Formik>
   )
 }

export default InterestForm
